<template>
  <div class="commission-address-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardBody>
            <h2 class="font-weight-bold mb-4">
              {{$t('COMMISSION_ADDRESS_LABEL_TITLE')}}
            </h2>

            <div class="commission-address-items">
              <CRow class="no-data" v-if="!commissionAddresses.length">
                <CCol lg="12" md="12" sm="12">
                  <div class="text">
                    {{$t('COMMISSION_ADDRESS_TEXT_NO_DATA')}}
                  </div>
                </CCol>
              </CRow>

              <CRow
                class="commission-address-item"
                v-for="item in commissionAddresses"
                :key="item.name"
              >
                <CCol lg="12" md="12" sm="12">
                  <CRow class="custom-input" alignVertical="center">
                    <CCol class sm="3">{{item.displayText}}:</CCol>
                    <CCol sm="9">
                      <CInput v-model="item.reward_address" :readonly="true">
                        <template #append>
                          <CButton color="light" @click="editAddress(item)">
                            <img
                              src="@/assets/images/ico-edit-user.svg"
                              alt="Edit"
                              class="hand edit-icon"
                            />
                          </CButton>
                        </template>
                      </CInput>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>

        <CModal class="change-address-modal" :show.sync="isShownModal" size="sm" :centered="true">
          <template #header>
            <h3>
              {{$t('COMMISSION_ADDRESS_LABEL_CHANGE_ADDRESS')}}
            </h3>
            <CButtonClose class="close-modal" @click="isShownModal = false" />
          </template>

          <div class="change-address-form">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider name="COMMISSION_ADDRESS_PAGE_ADDRESS_FIELD_NAME" rules="required">
                  <div slot-scope="{ errors }">
                    <CInput
                      :label="$t('COMMISSION_ADDRESS_PAGE_LABEL_NEW_ADDRESS')"
                      :placeholder="$t('COMMISSION_ADDRESS_PAGE_PLACEHOLDER_ADDRESS')"
                      v-model="selectedItem.reward_address"
                      class="address-input"
                    />
                    <span class="error-msg-wrap">
                      <span class="error-msg">{{errors[0]}}</span>
                    </span>
                  </div>
                </ValidationProvider>
              </CForm>
            </ValidationObserver>
          </div>

          <template #footer class="form-actions">
            <CButton @click="onCancel" class="btn btn-cancel">
              {{$t('COMMISSION_ADDRESS_BUTTON_CANCEL')}}
            </CButton>
            <CButton @click="onSubmit" color="primary" class="btn btn-save">
              {{$t('COMMISSION_ADDRESS_BUTTON_CHANGE')}}
            </CButton>
          </template>
        </CModal>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { endpoints, RoleType } from '@/constants';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'CommissionAddress',
  data() {
    return {
      commissionAddresses: [],
      isShownModal: false,
      selectedItem: {},
    };
  },
  async mounted() {
    await this.getCommissionAddresses();
  },
  methods: {
    async getCommissionAddresses() {
      try {
        const params = {
          offset: 0,
          limit: Number.MAX_SAFE_INTEGER,
        };
        const result = await this.$http.get(endpoints.getChildpoolCommissions, { params });
        const items = result.data.items;

        this.commissionAddresses = items.map(item => {
          const { platform, symbol } = item;
          item.displayText = platform === symbol ? symbol : `${symbol} (${platform})`;

          return item;
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('COMMISSION_ADDRESS_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },

    editAddress(item) {
      this.selectedItem = { ...item };
      this.isShownModal = true;
    },

    onCancel() {
      this.selectedItem = {};
      this.isShownModal = false;
    },

    async onSubmit() {
      this.$refs.observer.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.updateCommisionAddress();
      });
    },

    async updateCommisionAddress() {
      try {
        const data = {
          reward_address: this.selectedItem.reward_address,
        };
        const result = await this.$http.post(endpoints.requestChangeCommissionAddress(this.selectedItem.id), data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Your request has been sent.',
        });

        this.isShownModal = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.commission-address-container {
  width: 750px;

  &.wrapper {
    padding: 0 24px;
  }

  h2 {
    color: #657187;
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .commission-address-items {
    > .no-data {
      .text {
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        padding: 1em 0;
      }
    }

    .commission-address-item {
      padding: 0 8px;
    }

    .custom-input {
      padding-top: 4px;

      .input-group {
        .form-control {
          padding: 6px 12px;
          border: 1px solid #d8dbe0;
          border-radius: 0.25rem;
          text-align: left;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &[readonly] {
            background-color: #fff;
          }
        }

        .input-group-append {
          > .btn {
            height: 98%;
            border: 1px solid #d8dbe0;
            border-radius: 0.25rem;
            border-left-width: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        .edit-icon {
          color: #ffae00;
        }
      }
    }
  }
}

.change-address-modal {
  .modal-content {
    width: auto;
    > .modal-body {
      width: 400px;
    }

    .modal-header {
      border-bottom: none;
      padding-bottom: 0;

      h3 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 7px;
      }
    }
  }

  .change-address-form {
    .error-msg-wrap {
      .error-msg {
        color: red;
      }
    }
  }

  .modal-footer {
    padding-top: 0;
    border-top: none;
  }
}
</style>
